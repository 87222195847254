<template>
  <div>
     <v-dialog v-model="UserAccessdialog" width="400">
              <v-card v-if="editedUser" flat tile>
                  <v-card-title class="mediumoverline recordtoolbar white--text">
                       <v-avatar color="grey" class="white--text ml-0" size="40" v-if="!editedUser.Profile_Photo">                               
                            {{editedUser.Full_Name.split(' ')[0].substr(0,1)}}{{editedUser.Full_Name.split(' ')[1].substr(0,1)}}
                        </v-avatar>
                        <v-avatar size="40" v-if="editedUser.Profile_Photo">
                            <img :src="editedUser.Profile_Photo" style="object-fit: cover;" >
                        </v-avatar>
                      {{editedUser.Full_Name}} 
                  </v-card-title>
                  <v-card-text>
                      <v-select :items="ComputedModerationPermissionsOptions"
                      v-model="editedUser.ModerationPermissions"  item-text="DisplayName" label="Moderator Roles (Multiple Allowed)" multiple
                      />
                  </v-card-text>
                  <v-card-actions>
                      <v-btn @click="CloseNonGuestUserDialog()" dark color="warning">
                          Cancel
                      </v-btn>
                      <v-spacer>
                      </v-spacer>
                      <v-btn @click="AssignModerationPermissions(editedUser)" dark color="green">
                          Save</v-btn>
                  </v-card-actions>
              </v-card>
      </v-dialog>
       <UserLookupTable :CanSelect="false" :SelectMethod="'OpenUserDialog'" @OpenUserDialog="OpenNonGuestUserDialog" :AdditionalHeaders="[]" :ShowExpand="false"
        :UsersLookupArray="AllUsersandModerators" :SystemEntities="SystemEntities" :TableName="'Users'" :Rules="[]" :UserHeaders="UserHeaders" :CanAdd="false" @AddMethod="''"
        />
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import UserLookupTable from '@/components/General/UserLookupTable'
export default {
    props: ['System','SystemEntities','SiteModerators','ModerationItems'],
    components: {UserLookupTable},
    data() {
        return {
          UserHeaders: [
                {text: 'Full Name', value: 'Full_Name', class: 'overline'},
                {text: 'Business Unit', value: 'Business_Unit.Name', class: 'overline'},
                {text: 'Is Guest', value: 'IsGuestUser', class: 'overline'},
                {text: 'Is Moderator', value: 'Is_Moderator', class: 'overline'},
            ],
          ModerationPermissionsOptions: [
        'Articles',
        'Blogs',
        'Classifieds',
        'Digital Business Cards',
        'Events',
        'FAQ',
        'Forums',
        'Polls',
        'Groups',
        'Meetups',
        'Members',
        'Landing Pages',
        'Banner Ads',
        'Notices',
        'Wiki',
        'Glossary',
        'Public Articles',
        'Public Blogs',
        'Public Classifieds',
        'Public Events',
        'Public FAQ',
        'Public Forums',
        'Public Polls',
        'Public Groups',
        'Public Meetups',
        'Public Members',
        'Public Landing Pages',
        'Public Banner Ads',
        'Public Notices',
        'Public Wiki',
        'Public Glossary',
        'Public Digital Business Cards',
      ],
            UserAccessdialog: false,
            editedUser: '',
            editedUserIndex: -1,
        }
    },	
    computed:{
      ComputedModerationPermissionsOptions(){
      let arr1 = this.ModerationPermissionsOptions
      let arr2 = this.SystemEntities.filter(obj => obj.Entity_Type && obj.Entity_Type.Name === 'Classified Listing').map(entity => {
        return entity.id.split('_').join(' ')+' Classified Listings'
      })
      return arr1.concat(arr2).filter(item => {
        if(this.ModerationItems){
          return this.ModerationItems.includes(item)
        }
        else{
          return item
        }
      })
    },
    AllUsersandModerators(){
      //basically two tricks
      //1) will have to become conscious of what the prefilter is in order to determine if dude is moderator for the filter in query perhaps ActiveLibrary.Moderators become funciton ActiveFilterModerators(ActiveFilter)
      //2) when we update boh oh boy, do not simply update, only interrogate the filter otehweise you remove other moderationpermissions. splice and push applies, but only on check
            return this.$store.state.Users
            .map(user => {
                let userobj = Object.assign({},user)
                if(this.ModerationItems && this.IsModerator(this.ModerationItems,user)){
                    userobj.Is_Moderator = true
                }
                else if(!this.ModerationItems){
                  let oncheck = this.SiteModerators.find(obj => obj.id === user.id)
                  if(oncheck && oncheck.ModerationPermissions && oncheck.ModerationPermissions.length > 0){                    
                    userobj.Is_Moderator = true
                  }
                }
                else{
                    userobj.Is_Moderator = false
                }
            // except of course if no moderator filter?
                return userobj
            })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        IsModerator(items,user){
          let modoncheck = this.SiteModerators.find(obj => obj.id === user.id)
          return modoncheck && modoncheck.ModerationPermissions && modoncheck.ModerationPermissions.some(r=> items.includes(r))
        },
        AssignModerationPermissions(editedUser){
          db.collection('sitemoderators').doc(editedUser.id).set({
                Full_Name: editedUser.Full_Name,
                Name: editedUser.Name,
                  Surname: editedUser.Surname,
                  ModerationPermissions: editedUser.ModerationPermissions,                        
              }).then(result => {
              let length = editedUser.ModerationPermissions.length
              let text = `Updated User Moderator Roles to a total of `+length+`:
              <ul>`          
              editedUser.ModerationPermissions.map((role,i) => {
              text = text+`<li>`+role+`</li>`
              if(i-1+2 === length){
              text = text+`
              </ul>`
              }
          })
              let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: text,
              snackbartop: true,
              snackbarmultiLine: true
              }
              this.$emit('ActivateSnackbar',true,snackbarcontent)
          })
        
        },
        OpenNonGuestUserDialog(user){
            this.editedUser = user
            this.editedUserIndex = this.AllUsersandModerators.indexOf(user)
            db.collection('sitemoderators').doc(this.editedUser.id).get().then(snapshot => {
                let moderatordata = snapshot.data()
                if(moderatordata && moderatordata.ModerationPermissions){
                    this.editedUser.ModerationPermissions = moderatordata.ModerationPermissions
                    if(this.ModerationItems){
                      this.editedUser.AssignedModerationPermissions = moderatordata.ModerationPermissions
                    }
                }
                else if(this.ModerationItems){
                      this.editedUser.AssignedModerationPermissions = []
                    }
                this.UserAccessdialog = true
            })
        },
        CloseNonGuestUserDialog(){            
            this.UserAccessdialog = false         
            this.editedUser = ''
            this.editedUserIndex = -1   
        },
    }
}
</script>

<style>

</style>



